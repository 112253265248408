import { FreightPricingPortalError } from "../models/FreightPricingPortalError";
import { ApolloError } from "apollo-boost";
import { isDefined } from "@/common/types/IsDefined";

export const transformApolloErrorToFreightPricingPortalErrors = (
  apolloError: ApolloError
): Array<FreightPricingPortalError> => {
  return apolloError.graphQLErrors
    .map<FreightPricingPortalError>((gqlError) => {
      return {
        errors: [
          {
            message: gqlError.message,
            path: gqlError.path ? gqlError.toString() : undefined,
          },
        ],
        message: apolloError.message,
      };
    })
    .filter(isDefined);
};
