import { BookingStatusOptions } from "@/common/collections/HandlingBookingStatuses";
import { formatHoursMinutesWithColon } from "@/common/formatters/DateFormatter";
import { formatThousandsAndDecimalsWithNullAndUndefinedValuesAccepted } from "@/common/numbers";
import {
  firstLetterCapital,
  handleUserName,
} from "@/common/string/GeneralStringFormatting";
import { NoShowLateHandlingTypes } from "@/common/types/NoShowLateHandlingTypes";
import { Indent, Txt } from "@stenajs-webui/core";
import { createColumnConfig, StandardTableConfig } from "@stenajs-webui/grid";
import { format, parseISO } from "date-fns";
import { ReactNode } from "react";
import { RoutePairBooking } from "../transformers/InvoiceSpecificationDataToHandlingTypeDataTransformer";
import {
  ViewSpecificationLateHandlingRouteBookingTableColumnKeys,
  ViewSpecificationNoShowRouteBookingTableColumnKeys,
} from "./RowsAndColumnsConfig";

export const noShowRouteBookingTableConfig = (
  invoiceCurrencyCode: string | null,
  renderBookingLink?: (bookingNo: string) => ReactNode
): StandardTableConfig<
  RoutePairBooking,
  ViewSpecificationNoShowRouteBookingTableColumnKeys
> => {
  return {
    keyResolver: (item) => String(item.id),
    disableSorting: false,
    // disableInfiniteList needs to be true for the css print to work
    disableInfiniteList: true,
    columns: {
      bookingNo: createColumnConfig((item) => item.bookingNo, {
        columnLabel: "Booking no.",
        renderCell: renderBookingLink
          ? ({ value }) => {
              return <Indent>{renderBookingLink(String(value))}</Indent>;
            }
          : undefined,
      }),
      handlingStatus: createColumnConfig((item) => item.handlingStatus, {
        columnLabel: "Status",
        itemLabelFormatter: (value, item) => {
          if (
            value !== BookingStatusOptions.INVOICED &&
            value !== BookingStatusOptions.THRESHOLD
          ) {
            return "Excluded";
          }

          return firstLetterCapital(value ?? "");
        },
      }),
      routeCode: createColumnConfig((item) => item.routeCode, {
        columnLabel: "Route",
      }),
      departureDate: createColumnConfig((item) => item.departureDate, {
        columnLabel: "Dep. date",
      }),
      departureTime: createColumnConfig((item) => item.departureTime, {
        columnLabel: "Dep. time",
        renderCell: ({ label, value, item }) => {
          return (
            <Indent>
              <Txt>
                {item.departureTime &&
                  formatHoursMinutesWithColon(item.departureTime)}
              </Txt>
            </Indent>
          );
        },
      }),
      vehicleType: createColumnConfig(
        (item) => firstLetterCapital(item.vehicleType ?? ""),
        {
          columnLabel: "Vehicle",
        }
      ),
      vehicleReg: createColumnConfig((item) => item.vehicleReg, {
        columnLabel: "Vehicle no.",
      }),
      trailerReg: createColumnConfig((item) => item.trailerReg, {
        columnLabel: "Trailer reg.",
      }),
      customerReference: createColumnConfig((item) => item.customerReference, {
        columnLabel: "Cust. ref.",
      }),
      articlePrice: createColumnConfig((item) => item.articlePrice, {
        columnLabel: `Price (${invoiceCurrencyCode})`,
        renderCell: ({ label, value, item }) => {
          return (
            <Indent>
              <Txt>
                {formatThousandsAndDecimalsWithNullAndUndefinedValuesAccepted(
                  value
                )}
              </Txt>
            </Indent>
          );
        },
      }),
    },
    columnOrder: [
      "bookingNo",
      "handlingStatus",
      "routeCode",
      "departureDate",
      "departureTime",
      "vehicleType",
      "vehicleReg",
      "trailerReg",
      "customerReference",
      "articlePrice",
    ],
  };
};

export const lateHandlingRouteBookingTableConfig = (
  invoiceCurrencyCode: string | null,
  renderBookingLink?: (bookingNo: string) => ReactNode
): StandardTableConfig<
  RoutePairBooking,
  ViewSpecificationLateHandlingRouteBookingTableColumnKeys
> => {
  return {
    keyResolver: (item) => String(item.id),
    disableSorting: false,
    // disableInfiniteList needs to be true for the css print to work
    disableInfiniteList: true,
    columns: {
      bookingNo: createColumnConfig((item) => item.bookingNo, {
        columnLabel: "Booking no.",
        renderCell: renderBookingLink
          ? ({ value }) => {
              return <Indent>{renderBookingLink(String(value))}</Indent>;
            }
          : undefined,
      }),
      handlingType: createColumnConfig((item) => item.handlingType, {
        columnLabel: "Type",
        itemLabelFormatter: (value, item) => {
          if (value === NoShowLateHandlingTypes.LATECANCEL) {
            return "Cancel";
          }
          if (value === NoShowLateHandlingTypes.LATECHANGE) {
            return "Transfer";
          }

          return firstLetterCapital(value ?? "");
        },
      }),
      routeCode: createColumnConfig((item) => item.routeCode, {
        columnLabel: "Route",
      }),
      handlingStatus: createColumnConfig((item) => item.handlingStatus, {
        columnLabel: "Status",
        itemLabelFormatter: (value, item) => {
          if (
            value !== BookingStatusOptions.INVOICED &&
            value !== BookingStatusOptions.THRESHOLD
          ) {
            return "Excluded";
          }

          return firstLetterCapital(value ?? "");
        },
      }),
      departureDate: createColumnConfig((item) => item.departureDate, {
        columnLabel: "Dep. date",
      }),
      departureTime: createColumnConfig(
        (item) =>
          item.departureTime && formatHoursMinutesWithColon(item.departureTime),
        {
          columnLabel: "Dep. time",
          renderCell: ({ label, value, item }) => {
            return (
              <Indent>
                <Txt>
                  {item.departureTime &&
                    formatHoursMinutesWithColon(item.departureTime)}
                </Txt>
              </Indent>
            );
          },
        }
      ),
      lateHandlingDate: createColumnConfig((item) => item.lateHandlingDate, {
        columnLabel: "Cancel/Transfer date",
        itemLabelFormatter: (value, item) => {
          return value ? format(parseISO(value), "yyyy-MM-dd, HH:mm") : "";
        },
      }),
      lateHandlingUser: createColumnConfig(
        (item) => handleUserName(item.lateHandlingUser),
        {
          columnLabel: "User",
        }
      ),
      vehicleType: createColumnConfig(
        (item) => firstLetterCapital(item.vehicleType ?? ""),
        {
          columnLabel: "Vehicle",
        }
      ),
      vehicleReg: createColumnConfig((item) => item.vehicleReg, {
        columnLabel: "Vehicle no.",
      }),
      trailerReg: createColumnConfig((item) => item.trailerReg, {
        columnLabel: "Trailer reg.",
      }),
      customerReference: createColumnConfig((item) => item.customerReference, {
        columnLabel: "Cust. ref.",
      }),
      articlePrice: createColumnConfig((item) => item.articlePrice, {
        columnLabel: `Price (${invoiceCurrencyCode})`,
        renderCell: ({ label, value, item }) => {
          return (
            <Indent>
              <Txt>
                {formatThousandsAndDecimalsWithNullAndUndefinedValuesAccepted(
                  value
                )}
              </Txt>
            </Indent>
          );
        },
      }),
    },
    columnOrder: [
      "bookingNo",
      "handlingType",
      "routeCode",
      "handlingStatus",
      "departureDate",
      "departureTime",
      "lateHandlingDate",
      "lateHandlingUser",
      "vehicleType",
      "vehicleReg",
      "trailerReg",
      "customerReference",
      "articlePrice",
    ],
  };
};
