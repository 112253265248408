import { formatThousandsAndDecimalsToPercentage } from "@/common/numbers";
import { Indent, Txt } from "@stenajs-webui/core";
import { Icon, stenaSailingRoundTrip } from "@stenajs-webui/elements";
import { createColumnConfig, StandardTableConfig } from "@stenajs-webui/grid";
import { cssColor } from "@stenajs-webui/theme";
import { OverviewBooking } from "../models/OverviewBooking";
import {
  OverviewBookingLateHandlingTableColumnKeys,
  OverviewBookingNoShowTableColumnKeys,
} from "./RowsAndColumnsConfig";
import {
  getBackgroundColorForPercentage,
  getTextColorForPercentage,
} from "./ThresholdBackgroundHelper";

export const OverviewNoShowTableConfig: StandardTableConfig<
  OverviewBooking,
  OverviewBookingNoShowTableColumnKeys
> = {
  keyResolver: (item) => String(item.id),
  disableSorting: false,
  columns: {
    fillFirstColumn: createColumnConfig((item) => item.fillFirstColumn, {
      columnLabel: "",
      width: "16px",
    }),
    routePairDescription: createColumnConfig(
      (item) => item.routePairDescription,
      {
        columnLabel: "Route",
        minWidth: "200px",
        renderCell: ({ label, value, item }) => {
          const routePairParts = item.routePairDescription?.split("<->");
          if (routePairParts?.length === 2) {
            return (
              <>
                <Indent num={0.5} />
                <Txt>{routePairParts?.[0]}</Txt>
                <Indent>
                  <Icon
                    icon={stenaSailingRoundTrip}
                    color={cssColor("--lhds-color-ui-700")}
                  />
                </Indent>
                <Txt>{routePairParts?.[1]}</Txt>
              </>
            );
          }
          return item.routePairDescription;
        },
      }
    ),
    quantityNoShow: createColumnConfig((item) => item.quantityNoShow, {
      columnLabel: "Qty",
      minWidth: "125px",
    }),
    includedNoShow: createColumnConfig((item) => item.includedNoShow, {
      columnLabel: "Included",
      minWidth: "125px",
    }),
    excludedNoShow: createColumnConfig((item) => item.excludedNoShow, {
      columnLabel: "Excluded",
      minWidth: "125px",
    }),
    shippedVolume: createColumnConfig((item) => item.shippedVolume, {
      columnLabel: "Shipped units",
      minWidth: "115px ",
    }),
    percentageNoShow: createColumnConfig((item) => item.percentageNoShow, {
      renderCell: ({ label, item }) => (
        <Indent>
          <Txt
            color={getTextColorForPercentage(
              item.percentageNoShow,
              item.customerThresholdNoShowPercentage
            )}
          >
            {formatThousandsAndDecimalsToPercentage(
              item.percentageNoShow / 100,
              1
            )}
          </Txt>
        </Indent>
      ),
      backgroundResolver: (item) =>
        getBackgroundColorForPercentage(
          item.percentageNoShow,
          item.customerThresholdNoShowPercentage
        ),
      columnLabel: "Percent",
      minWidth: "110px",
    }),
  },
  columnOrder: [
    "fillFirstColumn",
    "routePairDescription",
    "quantityNoShow",
    "includedNoShow",
    "excludedNoShow",
    "shippedVolume",
    "percentageNoShow",
  ],
};

export const OverviewLateHandlingTableConfig: StandardTableConfig<
  OverviewBooking,
  OverviewBookingLateHandlingTableColumnKeys
> = {
  keyResolver: (item) => String(item.id),
  disableSorting: false,
  columns: {
    fillFirstColumn: createColumnConfig((item) => item.fillFirstColumn, {
      columnLabel: "",
      width: "16px",
    }),
    routePairDescription: createColumnConfig(
      (item) => item.routePairDescription,
      {
        columnLabel: "Route",
        minWidth: "200px",
        renderCell: ({ label, value, item }) => {
          const routePairParts = item.routePairDescription?.split("<->");
          if (routePairParts?.length === 2) {
            return (
              <>
                <Indent num={0.5} />
                <Txt>{routePairParts?.[0]}</Txt>
                <Indent>
                  <Icon
                    icon={stenaSailingRoundTrip}
                    color={cssColor("--lhds-color-ui-700")}
                  />
                </Indent>
                <Txt>{routePairParts?.[1]}</Txt>
              </>
            );
          }
          return item.routePairDescription;
        },
      }
    ),
    quantityLateHandling: createColumnConfig(
      (item) => item.quantityLateHandling,
      {
        columnLabel: "Qty",
        minWidth: "125px",
      }
    ),
    includedLateHandling: createColumnConfig(
      (item) => item.includedLateHandling,
      {
        columnLabel: "Included",
        minWidth: "125px",
      }
    ),
    excludedLateHandling: createColumnConfig(
      (item) => item.excludedLateHandling,
      {
        columnLabel: "Excluded",
        minWidth: "125px",
      }
    ),
    shippedVolume: createColumnConfig((item) => item.shippedVolume, {
      columnLabel: "Shipped units",
      minWidth: "115px ",
    }),
    percentageLateHandling: createColumnConfig(
      (item) => item.percentageLateHandling,
      {
        renderCell: ({ label, item }) => (
          <Indent>
            <Txt
              color={getTextColorForPercentage(
                item.percentageLateHandling,
                item.customerThresholdLateHandlingPercentage
              )}
            >
              {formatThousandsAndDecimalsToPercentage(
                item.percentageLateHandling / 100,
                1
              )}
            </Txt>
          </Indent>
        ),
        backgroundResolver: (item) =>
          getBackgroundColorForPercentage(
            item.percentageLateHandling,
            item.customerThresholdLateHandlingPercentage
          ),
        columnLabel: "Percent",
        minWidth: "110px",
      }
    ),
  },
  columnOrder: [
    "fillFirstColumn",
    "routePairDescription",
    "quantityLateHandling",
    "includedLateHandling",
    "excludedLateHandling",
    "shippedVolume",
    "percentageLateHandling",
  ],
};
