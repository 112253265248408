import { BookingStatusOptions } from "@/common/collections/HandlingBookingStatuses";
import { groupBy } from "lodash";
import { InvoiceSpecificationBooking } from "../../invoice-specification/models/InvoiceSpecification";
import { noExcludedBookings } from "../../invoice-specification/utils/utils";
import { OneMonthBookings } from "../models/OneMonthBookings";

export type RoutePairBookings = InvoiceSpecificationBooking[];
export type RoutePairBooking = InvoiceSpecificationBooking;

export type RoutePairSummary = {
  handlingType: string | null;
  shippedUnits: number;
  custNo: number;
  routePairCode: string | null;
  noExcludedBookings: number;
  noOfNoShowLateHandling: number;
  invoiced: number;
  threshold: number;
  dni: number;
  total: number;
  routePairDescription: string | null;
};

export interface RoutePairData {
  bookings: RoutePairBookings;
  summary: RoutePairSummary;
}

export interface HandlingTypeData {
  handlingType: string;
  routePairData: Array<RoutePairData>;
}

export const invoiceSpecificationDataToHandlingTypeDataTransformer = (
  oneMonthBookingsData: OneMonthBookings
): HandlingTypeData[] => {
  const groupedByHandlingType = groupBy(
    oneMonthBookingsData.nslhBookings,
    (booking) => booking.handlingTypeGrouped
  );
  return Object.keys(groupedByHandlingType).map((handlingTypeGrouped) => {
    const handlingTypeBookings = groupedByHandlingType[handlingTypeGrouped];

    const groupedByRoutePairCode = groupBy(
      handlingTypeBookings,
      (i) => i.routePairCode
    );
    return {
      handlingType: handlingTypeGrouped,
      routePairData: Object.keys(groupedByRoutePairCode).map(
        (routePairCode) => {
          const routePairBookings = groupedByRoutePairCode[routePairCode];
          const routePairVolume = oneMonthBookingsData.routePairVolumes.find(
            (rpv) => rpv.routePairCode === routePairCode
          );
          const shippedVolume = routePairVolume?.shippedVolume ?? 0;
          return {
            bookings: routePairBookings,
            summary: {
              noExcludedBookings: noExcludedBookings(routePairBookings),
              handlingType: handlingTypeGrouped,
              custNo: oneMonthBookingsData.custNo,
              routePairCode: routePairBookings[0].routePairCode,
              shippedUnits: shippedVolume,
              noOfNoShowLateHandling: routePairBookings.length,
              invoiced: routePairBookings.filter(
                (rpb) => rpb.handlingStatus === BookingStatusOptions.INVOICED
              ).length,
              threshold: routePairBookings.filter(
                (rpb) => rpb.handlingStatus === BookingStatusOptions.THRESHOLD
              ).length,
              dni: routePairBookings.filter(
                (rpb) => rpb.handlingStatus === BookingStatusOptions.DONOTINV
              ).length,
              total: routePairBookings.length,
              routePairDescription: routePairBookings[0].routePairDescription,
            },
          };
        }
      ),
    };
  });
};
