import { cssColor } from "@stenajs-webui/theme";
import {
  Card,
  CardBody,
  CardHeader,
  Icon,
  stenaBusinessInvoice,
} from "@stenajs-webui/elements";
import * as React from "react";
import { InfoPair } from "../info-pair/InfoPair";

interface Props {
  invoiceNumber: number;
  invoiceDate: string;
  consignmentPeriod: string;
  totalIncVat: string;
}

export const InvoiceSpecificationCard: React.FC<Props> = ({
  invoiceNumber,
  invoiceDate,
  consignmentPeriod,
  totalIncVat,
}) => (
  <Card>
    <CardHeader
      variant={"compact"}
      text={"Invoice"}
      contentLeft={
        <Icon
          icon={stenaBusinessInvoice}
          size={16}
          color={cssColor("--lhds-color-ui-700")}
        />
      }
    />
    <CardBody flexDirection={"row"} flexWrap={"wrap"}>
      <InfoPair
        leftContent={"Number"}
        leftTextColor={cssColor("--lhds-color-ui-500")}
        leftTextSize={"small"}
        leftTextWhiteSpace={"nowrap"}
        rightTextSize={"small"}
        rightTextWhiteSpace={"nowrap"}
        rightTextColor={cssColor("--lhds-color-ui-700")}
        indentAfterComponent={1.5}
        borderBottom={false}
        rightContent={invoiceNumber}
      />

      <InfoPair
        leftContent={"Date"}
        leftTextColor={cssColor("--lhds-color-ui-500")}
        leftTextSize={"small"}
        leftTextWhiteSpace={"nowrap"}
        rightTextSize={"small"}
        rightTextWhiteSpace={"nowrap"}
        rightTextColor={cssColor("--lhds-color-ui-700")}
        indentAfterComponent={1.5}
        borderBottom={false}
        rightContent={invoiceDate}
      />

      <InfoPair
        leftContent={"Consignment period"}
        leftTextColor={cssColor("--lhds-color-ui-500")}
        leftTextSize={"small"}
        leftTextWhiteSpace={"nowrap"}
        rightTextSize={"small"}
        rightTextWhiteSpace={"nowrap"}
        rightTextColor={cssColor("--lhds-color-ui-700")}
        indentAfterComponent={1.5}
        borderBottom={false}
        rightContent={consignmentPeriod}
      />

      <InfoPair
        leftContent={"Total (Inc VAT)"}
        leftTextColor={cssColor("--lhds-color-ui-500")}
        leftTextSize={"small"}
        leftTextWhiteSpace={"nowrap"}
        rightTextSize={"small"}
        rightTextWhiteSpace={"nowrap"}
        rightTextColor={cssColor("--lhds-color-ui-700")}
        indentAfterComponent={1.5}
        borderBottom={false}
        rightContent={totalIncVat}
      />
    </CardBody>
  </Card>
);
