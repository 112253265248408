import { cssColor } from "@stenajs-webui/theme";

export const getTextColorForPercentage = (
  noShowLateHandlingPercentage: number,
  customerNoShowLateHandlingThreshold: number | null
): string | undefined => {
  return customerNoShowLateHandlingThreshold &&
    noShowLateHandlingPercentage > customerNoShowLateHandlingThreshold
    ? cssColor("--lhds-color-red-600")
    : customerNoShowLateHandlingThreshold &&
      noShowLateHandlingPercentage <= customerNoShowLateHandlingThreshold
    ? cssColor("--lhds-color-green-600")
    : undefined;
};

export const getBackgroundColorForPercentage = (
  noShowLateHandlingPercentage: number,
  noShowLateHandlingPercentageThreshold: number | null
): string | undefined =>
  noShowLateHandlingPercentageThreshold &&
  noShowLateHandlingPercentage > noShowLateHandlingPercentageThreshold
    ? cssColor("--lhds-color-red-50")
    : noShowLateHandlingPercentageThreshold &&
      noShowLateHandlingPercentage <= noShowLateHandlingPercentageThreshold
    ? cssColor("--lhds-color-green-50")
    : undefined;
