import React, { useState } from "react";
import { Window } from "@stenajs-webui/modal";
import { Indent, Row, Space, Spacing, Txt } from "@stenajs-webui/core";
import {
  FlatButton,
  PrimaryButton,
  stenaSortDown,
  stenaSortUp,
} from "@stenajs-webui/elements";
import { FreightPricingPortalError } from "../models/FreightPricingPortalError";

interface Props {
  error: FreightPricingPortalError;
  onClose?: () => void;
  visible: boolean;
}

interface ErrorDisplayProps {
  bodyText?: string;
  detailsText?: string;
  onActionButtonClick?: () => void;
}

const ErrorDisplayBody: React.FC<ErrorDisplayProps> = ({
  bodyText,
  detailsText,
  onActionButtonClick,
}) => {
  const [isDetailsOpen, setDetailsOpen] = useState(false);

  return (
    <>
      <Indent>
        {bodyText}
        <Row spacing={2}>
          <FlatButton
            size={"medium"}
            label={isDetailsOpen ? "Hide details" : "Show details"}
            onClick={() => setDetailsOpen(!isDetailsOpen)}
            leftIcon={isDetailsOpen ? stenaSortUp : stenaSortDown}
          />
        </Row>
        {isDetailsOpen && <Txt whiteSpace={"pre-line"}>{detailsText}</Txt>}
        {onActionButtonClick && (
          <Row justifyContent={"end"}>
            <Spacing>
              <Space num={3} />
              <PrimaryButton label={"Ok"} onClick={onActionButtonClick} />
            </Spacing>
          </Row>
        )}
      </Indent>
    </>
  );
};

export const ErrorDisplayModal: React.FC<Props> = ({
  error,
  onClose,
  visible,
}) => {
  const errorDetails =
    (error.message ?? error.description ?? " ") +
    "\n" +
    error.errors.map((error) => error.message).join("\n");

  return (
    <Window
      headerText={"Technical problems"}
      width={"600px"}
      isOpen={visible}
      shouldCloseOnOverlayClick={false}
      onRequestClose={() => onClose?.()}
    >
      <ErrorDisplayBody
        bodyText={
          "Something went wrong. Your request has not been processed due to technical problems. Please try again."
        }
        detailsText={errorDetails}
        onActionButtonClick={() => onClose?.()}
      />
    </Window>
  );
};
