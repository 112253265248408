import { useWindowSize } from "@/common/hooks/UseWindowSize";
import { formatThousandsAndDecimalsWithNullAndUndefinedValuesAccepted } from "@/common/numbers";
import { NoShowLateHandlingTypes } from "@/common/types/NoShowLateHandlingTypes";
import { IndentValues } from "@/common/utils/IndentValues";
import { SpacingValues } from "@/common/utils/SpacingValues";
import { Indent, Row, Spacing } from "@stenajs-webui/core";
import { Card } from "@stenajs-webui/elements";
import { flatten } from "lodash";
import * as React from "react";
import { ReactNode } from "react";
import { AccountSpecificationCard } from "../../invoice-specification-cards/AccountSpecificationCard";
import { InvoiceSpecificationCard } from "../../invoice-specification-cards/InvoiceSpecificationCard";
import { InvoiceSpecification } from "../models/InvoiceSpecification";
import { invoiceSpecificationDataToHandlingTypeDataTransformer } from "../transformers/InvoiceSpecificationDataToHandlingTypeDataTransformer";
import { ViewSpecificationLateHandlingSummaryAndRoutePairTableWrapper } from "./ViewSpecificationLateHandlingSummaryAndRoutePairTableWrapper";
import { ViewSpecificationNoShowSummaryAndRoutePairTableWrapper } from "./ViewSpecificationNoShowSummaryAndRoutePairTableWrapper";

interface Props {
  invoiceSpecificationData: InvoiceSpecification[];
  showExcludedBookings: boolean;
  renderBookingLink?: (bookingNo: string) => ReactNode;
}

const formatDateStringToString = (dateString: string) => {
  return dateString.slice(0, 10);
};

const formatNumberToTwoDigit = (numberToFormat: number) => {
  return String(numberToFormat).padStart(2, "0");
};

export const ViewSpecificationView: React.FC<Props> = ({
  invoiceSpecificationData,
  showExcludedBookings,
  renderBookingLink,
}) => {
  const { width: windowWidth } = useWindowSize();
  const smallWindowWidth = windowWidth <= 1420;

  const groupedHandlingTypeData = invoiceSpecificationData.map((data) =>
    invoiceSpecificationDataToHandlingTypeDataTransformer(
      data,
      showExcludedBookings
    )
  );

  if (!(invoiceSpecificationData && invoiceSpecificationData.length > 0)) {
    return null;
  }

  const firstInvoiceSpecificationData = invoiceSpecificationData[0];
  const handlingTypeDatas = flatten(groupedHandlingTypeData);

  return (
    <>
      <Indent
        num={IndentValues.TWENTYFOUR}
        className={"print-background-color"}
      >
        <Spacing num={SpacingValues.TWENTYFOUR}>
          <Row
            className="invoice-specification-cards"
            flexDirection={smallWindowWidth ? "column" : "row"}
            width={smallWindowWidth ? "100vw" : undefined}
          >
            <InvoiceSpecificationCard
              invoiceNumber={firstInvoiceSpecificationData.invoiceNo}
              invoiceDate={formatDateStringToString(
                firstInvoiceSpecificationData.invoiceDate!
              )}
              consignmentPeriod={`${
                firstInvoiceSpecificationData.nslhYear
              }-${formatNumberToTwoDigit(
                firstInvoiceSpecificationData.nslhMonth
              )}`}
              totalIncVat={`${formatThousandsAndDecimalsWithNullAndUndefinedValuesAccepted(
                firstInvoiceSpecificationData.netAmount
              )} ${firstInvoiceSpecificationData.invoiceCurrencyCode}`}
            />
            {smallWindowWidth ? (
              <Spacing num={SpacingValues.TWELVE} />
            ) : (
              <Indent num={IndentValues.TWELVE} />
            )}

            <AccountSpecificationCard
              accountName={firstInvoiceSpecificationData.custName ?? ""}
              accountNumber={firstInvoiceSpecificationData.custNo}
              accountIndex={firstInvoiceSpecificationData.custIndex ?? ""}
              noShowThreshold={String(
                firstInvoiceSpecificationData.thresholdNoShow
              )}
              lateHandlingThreshold={String(
                firstInvoiceSpecificationData.thresholdLateHandling
              )}
            />
          </Row>
        </Spacing>

        <Card className="card-table-wrapper">
          {
            // This div needs to be here for the css print to work correctly
          }
          <div>
            {handlingTypeDatas.map((handlingTypeData, i) => (
              <React.Fragment
                key={`view-specification-${handlingTypeData.handlingType}-section`}
              >
                {handlingTypeData.handlingType ===
                  NoShowLateHandlingTypes.NOSHOW && (
                  <ViewSpecificationNoShowSummaryAndRoutePairTableWrapper
                    routePairsData={handlingTypeData.routePairData}
                    renderBookingLink={renderBookingLink}
                  />
                )}

                {handlingTypeData.handlingType ===
                  NoShowLateHandlingTypes.LATE_HANDL && (
                  <ViewSpecificationLateHandlingSummaryAndRoutePairTableWrapper
                    routePairsData={handlingTypeData.routePairData}
                    renderBookingLink={renderBookingLink}
                  />
                )}
              </React.Fragment>
            ))}
          </div>
        </Card>
      </Indent>
    </>
  );
};
