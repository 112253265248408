export const DateFormats = {
  yearAndMonth: "yyyy-MM",
  fullDate: "yyyy-MM-dd",
  fullMonthName: "LLLL",
  shortDayAndDate: "EEE, LLL M",
  fullDateAndTime: "yyyy-MM-dd HH:mm",
  fullDateAndTimeSystem: "yyyy-MM-ddTHH:MM",
  weekDayName: "EEEE", // Tuesday
  weekDayNameShort: "EEE", // Tue
  dateAndMonth: "d MMM", // 2 Jan
  monthAndDate: "MMM do", // Jan 2nd
  weekDayMonthDateAndYear: "EEE, MMM do yyyy", // Tue, Jan 2nd 2020
  time: "HH:mm",
};
