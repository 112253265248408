import { SpacingValues } from "@/common/utils/SpacingValues";
import {
  Box,
  Row,
  SeparatorLine,
  Space,
  Spacing,
  Text,
} from "@stenajs-webui/core";
import {
  Banner,
  Card,
  CardHeader,
  Icon,
  Label,
  Link,
  stenaInfoCircle,
  stenaQuestionCircle,
} from "@stenajs-webui/elements";
import { StandardTable } from "@stenajs-webui/grid";
import { cssColor } from "@stenajs-webui/theme";
import { Popover } from "@stenajs-webui/tooltip";
import { groupBy } from "lodash";
import React from "react";
import { useNavigate } from "react-router-dom";
import { useWindowSize } from "../../hooks/UseWindowSize";
import { IndentValues } from "../../utils/IndentValues";
import { IconSize } from "../icons/IconSize";
import {
  OverviewLateHandlingTableConfig,
  OverviewNoShowTableConfig,
} from "./config/OverviewTableConfig";
import { OverviewBooking } from "./models/OverviewBooking";

interface Props {
  overviewBookings: OverviewBooking[];
}

export const OverviewTableWrapper: React.FC<Props> = ({ overviewBookings }) => {
  const { width: windowWidth } = useWindowSize();
  const smallWindowWidth = windowWidth <= 1450;
  const navigate = useNavigate();
  const groupByCustomers = groupBy(
    overviewBookings,
    (bookings) => bookings.customer.custNo
  );
  const groupedBookings = Object.values(groupByCustomers);

  return (
    <>
      {groupedBookings.map((booking) => {
        const noShowBookings = booking.filter((b) => b.quantityNoShow > 0);
        const lateHandlingBookings = booking.filter(
          (b) => b.quantityLateHandling > 0
        );

        return (
          <Card
            key={`public-overview-table-wrapper-${booking[0].customer.custNo}`}
          >
            <CardHeader
              text={`Route summary - ${booking[0].customer.custIndex} (${booking[0].customer.custNo})`}
              contentRight={
                <>
                  <Link
                    onClick={() => navigate(booking[0].pathToOneMonthBookings)}
                  >
                    Bookings
                  </Link>
                  <Space />
                  <Popover
                    variant={"standard"}
                    placement={"bottom-end"}
                    content={
                      <Box indent={2} spacing={2}>
                        <Label text={"Qty"} row>
                          <Text>
                            All bookings that are No show or Late handling
                          </Text>
                        </Label>
                        <Label text={"Included"} row>
                          <Text>Bookings subject for invoice</Text>
                        </Label>
                        <Label text={"Excluded"} row>
                          <Text>
                            Bookings currently not subject for invoice
                          </Text>
                        </Label>
                        <Label text={"Shipped units"} row>
                          <Text>Units shipped on route for selected month</Text>
                        </Label>
                        <Label text={"Percent"} row>
                          <Text>Included/shipped units</Text>
                        </Label>
                      </Box>
                    }
                  >
                    <Icon
                      icon={stenaQuestionCircle}
                      size={IconSize.Small}
                      color={cssColor("--lhds-color-blue-500")}
                    />
                  </Popover>
                </>
              }
            />
            <SeparatorLine />
            <Row
              display={"flex"}
              flexDirection={smallWindowWidth ? "column" : "row"}
            >
              <Box width={"100%"}>
                <Box justifyContent={"center"} height={"57px"}>
                  <CardHeader variant="compact" text="No show" />
                  <SeparatorLine />
                </Box>
                {noShowBookings.length > 0 ? (
                  <>
                    <StandardTable
                      config={OverviewNoShowTableConfig}
                      items={noShowBookings}
                      variant={"condensed"}
                    />
                    <SeparatorLine />
                    {smallWindowWidth ? null : (
                      <Spacing num={SpacingValues.TWELVE} />
                    )}
                  </>
                ) : (
                  <Box
                    spacing={SpacingValues.TWENTYFOUR}
                    indent={IndentValues.TWENTYFOUR}
                    minWidth={"645px"}
                  >
                    <Banner
                      variant={"info"}
                      text={"No No show bookings for this month"}
                      icon={stenaInfoCircle}
                    />
                  </Box>
                )}
              </Box>
              {smallWindowWidth ? null : (
                <SeparatorLine vertical size={"unset"} width={"2px"} />
              )}
              <Box width={"100%"}>
                <Box justifyContent={"center"} height={"57px"}>
                  <CardHeader variant="compact" text={"Late handling"} />
                  <SeparatorLine />
                </Box>

                {lateHandlingBookings.length > 0 ? (
                  <>
                    <StandardTable
                      config={OverviewLateHandlingTableConfig}
                      items={lateHandlingBookings}
                      variant={"condensed"}
                    />
                    <SeparatorLine />
                    <Spacing num={SpacingValues.TWELVE} />
                  </>
                ) : (
                  <Box
                    spacing={SpacingValues.TWENTYFOUR}
                    indent={IndentValues.TWENTYFOUR}
                    minWidth={"645px"}
                  >
                    <Banner
                      variant={"info"}
                      text={"No Late handling bookings for this month"}
                      icon={stenaInfoCircle}
                    />
                  </Box>
                )}
              </Box>
            </Row>
          </Card>
        );
      })}
      <Spacing />
    </>
  );
};
