import { IndentValues } from "@/common/utils/IndentValues";
import { SpaceValues } from "@/common/utils/SpaceValues";
import { SpacingValues } from "@/common/utils/SpacingValues";
import { Box, Row, SeparatorLine, Space } from "@stenajs-webui/core";
import { CardHeader } from "@stenajs-webui/elements";
import { StandardTable } from "@stenajs-webui/grid";
import React from "react";
import { InfoPairRow } from "../../info-pair/InfoPairRow";
import { ViewSpecificationRoutePairHeading } from "../../invoice-specification/components/ViewSpecificationRoutePairHeading";
import { lateHandlingRouteBookingTableConfig } from "../config/RouteBookingTableConfig";
import { RoutePairData } from "../transformers/InvoiceSpecificationDataToHandlingTypeDataTransformer";

interface Props {
  routePairsData: RoutePairData[];
}

export const OneMonthBookingsLateHandlingSummaryAndRoutePairTableWrapper: React.FC<
  Props
> = ({ routePairsData }) => {
  return (
    <div className="table-wrapper">
      <CardHeader text={"Late handling"} />
      <SeparatorLine />
      <Space num={SpaceValues.TWELVE} />

      {routePairsData.map((routePairData) => {
        const { summary, bookings } = routePairData;
        if (bookings.length === 0) {
          return null;
        }

        return (
          <Box
            indent={IndentValues.TWENTYFOUR}
            spacing={SpacingValues.TWELVE}
            key={`one-month-bookings-late-handling-summary-table-wrapper-${summary.handlingType}-${summary.routePairCode}`}
          >
            <Box
              className={"public-page-break-wrapper"}
              key={`${summary.handlingType}-${summary.routePairCode}`}
            >
              {summary.routePairDescription && (
                <Row>
                  <ViewSpecificationRoutePairHeading
                    heading={summary.routePairDescription}
                  />
                </Row>
              )}
              <Space num={SpaceValues.SIXTEEN} />
              <InfoPairRow
                noShippedUnits={summary.shippedUnits}
                quantity={summary.noOfNoShowLateHandling}
                noExcluded={summary.noExcludedBookings}
                noIncluded={
                  summary.noOfNoShowLateHandling - summary.noExcludedBookings
                }
              />
              <Space num={SpaceValues.SIXTEEN} />
              <StandardTable
                config={lateHandlingRouteBookingTableConfig}
                items={bookings}
                variant={"condensed"}
              />
            </Box>
          </Box>
        );
      })}
      <Space num={SpaceValues.TWELVE} />
    </div>
  );
};
