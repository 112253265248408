export const firstLetterCapital = (value: string) => {
  const lowerCaseValue = value.toLowerCase();
  return lowerCaseValue.charAt(0).toUpperCase() + lowerCaseValue.slice(1);
};

export const handleUserName = (userName: string | null) => {
  const stripedName =
    userName?.replace("EXTRANET\\", "").replace("LINE\\", "") ?? "";

  if (stripedName.toLowerCase() === "stena line") {
    return "Stena Line";
  }

  return firstLetterCapital(stripedName);
};
