import { Box, Row, Space } from "@stenajs-webui/core";
import { Select, SelectProps } from "@stenajs-webui/select";
import React from "react";
import { addZeroToMonth } from "../../dates/formatters";

const currentYear = new Date().getUTCFullYear();

export const years = [
  {
    id: currentYear,
    year: currentYear,
  },
  {
    id: currentYear - 1,
    year: currentYear - 1,
  },
  {
    id: currentYear - 2,
    year: currentYear - 2,
  },
  {
    id: currentYear - 3,
    year: currentYear - 3,
  },
];

const months = Array.from({ length: 12 }, (_, i) => i + 1);

export const yearOptions = years.map((y) => ({
  label: y.id,
  value: y.year,
}));

export const monthOptions = months.map((month) => ({
  label: addZeroToMonth(month),
  value: month,
}));

interface Props extends Omit<SelectProps<any>, "value" | "onChange"> {
  yearValue: number;
  monthValue: number;
  onYearValueChange: (yearValue: number) => void;
  onMonthValueChange: (monthValue: number) => void;
}

export const OverviewDateSelect: React.FC<Props> = ({
  yearValue,
  monthValue,
  onYearValueChange,
  onMonthValueChange,
  ...selectProps
}) => {
  const selectedYear = yearOptions.find((o) => o.value === yearValue);
  const selectedMonth = monthOptions.find((o) => o.value === monthValue);

  return (
    <Row>
      <Box width={"90px"}>
        <Select
          {...selectProps}
          options={yearOptions}
          value={selectedYear}
          onChange={(item) => item && onYearValueChange(item.value)}
        />
      </Box>
      <Space />
      <Box width={"65px"}>
        <Select
          {...selectProps}
          options={monthOptions}
          value={selectedMonth}
          onChange={(item) => item && onMonthValueChange(item.value)}
        />
      </Box>
    </Row>
  );
};
