import { useCallback } from "react";

export const normalizeNumericString = (
  input: string,
  {
    useNegativeValues = false,
    maxDecimals,
    maxNumbers,
  }: {
    maxNumbers?: number;
    maxDecimals?: number;
    useNegativeValues?: boolean;
  }
): string | undefined => {
  if (input === "") {
    return "";
  }

  if (input === "-") {
    return useNegativeValues ? input : undefined;
  }

  const inputWithDot = input.replace(/,/g, ".");
  const isNegative = parseFloat(inputWithDot) < 0;

  if (!useNegativeValues && isNegative) {
    return undefined;
  }

  const [before, after = ""] = inputWithDot
    .slice(isNegative ? 1 : 0)
    .split(".");

  if (maxDecimals != null && after.length > maxDecimals) {
    return undefined;
  }

  if (maxNumbers != null && before.length > maxNumbers) {
    return undefined;
  }

  if (
    maxDecimals === 0 &&
    new RegExp("^-?[0-9]+([,.]?)([0-9]+)?$").test(input)
  ) {
    return input.replace(/,|\./, "");
  }

  if (input && !new RegExp("^-?[0-9]+([,.]?)([0-9]+)?$").test(input)) {
    return undefined;
  }

  return input.replace(/\./, ",");
};

type Setter = (s: string) => void;
export const useNumericTextInputChange = (
  setValue: Setter,
  maxNumbers?: number,
  maxDecimals?: number,
  useNegativeValues?: boolean
): Setter =>
  useCallback(
    (input: string) => {
      const newValue = normalizeNumericString(input, {
        maxDecimals,
        maxNumbers,
        useNegativeValues,
      });
      if (newValue !== undefined) {
        setValue(newValue);
      }
    },
    [setValue, maxNumbers, maxDecimals, useNegativeValues]
  );
