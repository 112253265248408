import { TextInput, TextInputProps } from "@stenajs-webui/forms";
import React from "react";
import { useNumericTextInputChange } from "./NormalizeNumericString";

interface Props extends TextInputProps {
  maxNumbers?: number;
  maxDecimals?: number;
  useNegativeValues?: boolean;
  dataTestid?: string;
}

export const NumericTextInput: React.FC<Props> = ({
  maxNumbers,
  maxDecimals,
  useNegativeValues,
  dataTestid,
  onValueChange,
  ...props
}) => {
  const onValueTextChange = useNumericTextInputChange(
    (value) => {
      onValueChange?.(value);
    },
    maxNumbers,
    maxDecimals,
    useNegativeValues
  );

  return (
    <TextInput
      onValueChange={onValueTextChange}
      data-testid={dataTestid}
      {...props}
    />
  );
};
