import { Box, Indent } from "@stenajs-webui/core";
import {
  BreadCrumbs,
  FlatButton,
  stenaBusinessNslh,
  stenaPrint,
} from "@stenajs-webui/elements";
import { SwitchWithLabel } from "@stenajs-webui/forms";
import { PageHeader, PageHeading } from "@stenajs-webui/panels";
import React from "react";
import { ZIndex } from "../../../utils/ZIndexEnum";
import { cssColor } from "@stenajs-webui/theme";
import { Crumb } from "../../bread-crumbs/Crumb";

interface Props {
  pageHeading: string;
  breadCrumbLabel?: string;
  previousRoute: string;
  setExcludedBookings?: () => void;
  excludedBookings?: boolean;
}

export const ViewSpecificationScreenHeader: React.FC<Props> = ({
  pageHeading,
  breadCrumbLabel,
  setExcludedBookings,
  excludedBookings,
  previousRoute,
}) => {
  const createPrintPdf = () => {
    window.print();
  };

  return (
    <Box
      background={cssColor("--lhds-color-ui-50")}
      position={"sticky"}
      top={0}
      left={0}
      width={"100vw"}
      zIndex={ZIndex.lowest}
      className={"view-specification-header"}
    >
      <PageHeader
        renderPageHeading={() => (
          <PageHeading
            heading={pageHeading}
            icon={stenaBusinessNslh}
            contentRight={
              <>
                {setExcludedBookings && (
                  <>
                    <SwitchWithLabel
                      label={"Show excluded bookings"}
                      value={excludedBookings}
                      onValueChange={setExcludedBookings}
                    />
                    <Indent />
                  </>
                )}

                <FlatButton
                  leftIcon={stenaPrint}
                  label={"Print"}
                  onClick={createPrintPdf}
                />
              </>
            }
          />
        )}
        renderBreadCrumbs={() => (
          <BreadCrumbs>
            <Crumb label={"No show & Late handling"} />
            <Crumb label={"Follow up"} path={previousRoute} />
            <Crumb label={breadCrumbLabel ?? "Invoice Specification"} isLast />
          </BreadCrumbs>
        )}
      />
    </Box>
  );
};
