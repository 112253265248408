import React from "react";
import { Heading, Indent, Row } from "@stenajs-webui/core";
import { Icon, stenaSailingRoundTrip } from "@stenajs-webui/elements";

interface Props {
  heading: string;
}

export const ViewSpecificationRoutePairHeading: React.FC<Props> = ({
  heading,
}) => {
  const routePairParts = heading.split("<->");
  if (routePairParts.length === 2) {
    return (
      <Row alignItems={"center"}>
        <Heading variant={"h4"}>{routePairParts[0]}</Heading>
        <Indent>
          <Icon icon={stenaSailingRoundTrip} />
        </Indent>
        <Heading variant={"h4"}>{routePairParts[1]}</Heading>
      </Row>
    );
  }

  return <Heading variant={"h4"}>{heading}</Heading>;
};
