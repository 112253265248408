import { cssColor } from "@stenajs-webui/theme";
import {
  Box,
  BoxProps,
  Indent,
  Row,
  SeparatorLine,
  Spacing,
} from "@stenajs-webui/core";
import { ApolloError } from "apollo-boost";
import * as React from "react";
import { LeftContent, LeftContentProps } from "./LeftContent";
import { RightContent, RightContentProps } from "./RightContent";
import { SpacingValues } from "@/common/utils/SpacingValues";
import { IndentValues } from "@/common/utils/IndentValues";

interface Props extends LeftContentProps, RightContentProps {
  display?: BoxProps["display"];
  justifyContent?: BoxProps["justifyContent"];
  flexDirection?: BoxProps["flexDirection"];
  alignItems?: BoxProps["alignItems"];
  rowContainerWidth?: BoxProps["width"];
  boxContainerWidth?: BoxProps["width"];
  borderBottom?: boolean;
  spacingTopAndBottom?: number;
  indentBetweenRows?: number;
  indentAfterComponent?: number;
  loading?: boolean;
  error?: ApolloError;
}

export const InfoPair: React.FC<Props> = ({
  display = "flex",
  justifyContent = "space-between",
  alignItems,
  flexDirection,
  rowContainerWidth,
  boxContainerWidth,
  borderBottom = true,
  spacingTopAndBottom = SpacingValues.FOUR,
  indentBetweenRows = IndentValues.FOUR,
  indentAfterComponent = IndentValues.FOUR,
  loading,
  error,
  ...contentProps
}) => {
  return (
    <Box width={boxContainerWidth}>
      <Spacing num={spacingTopAndBottom} />
      <Row
        display={display}
        justifyContent={justifyContent}
        flexDirection={flexDirection}
        alignItems={alignItems}
        width={rowContainerWidth}
      >
        <LeftContent {...contentProps} />
        <Indent num={indentBetweenRows} />
        <RightContent {...contentProps} error={error} loading={loading} />
        <Indent num={indentAfterComponent} />
      </Row>
      <Spacing num={spacingTopAndBottom} />
      {borderBottom && (
        <SeparatorLine width="0.5px" color={cssColor("--lhds-color-ui-300")} />
      )}
    </Box>
  );
};
