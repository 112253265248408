import { BookingStatusOptions } from "@/common/collections/HandlingBookingStatuses";
import { formatThousandsAndDecimalsToPercentage } from "@/common/numbers";
import {
  RoutePairBookings,
  RoutePairData,
} from "../transformers/InvoiceSpecificationDataToHandlingTypeDataTransformer";

export const noShowLateHandlingPercentageString = (
  noIncludedNoShowOrLateHandling: number,
  shippedUnits: number
): string => {
  if (shippedUnits === 0) {
    return "100";
  }
  const percentage = noIncludedNoShowOrLateHandling / shippedUnits;
  return percentage > 1
    ? "100"
    : formatThousandsAndDecimalsToPercentage(
        noIncludedNoShowOrLateHandling / Number(shippedUnits),
        1
      );
};

export const noShowLateHandlingPercentageNumber = (
  noIncludedNoShowOrLateHandling: number,
  shippedUnits: number
): number => {
  if (shippedUnits === 0) {
    return 100;
  }
  const percentage = noIncludedNoShowOrLateHandling / shippedUnits;
  return percentage > 1
    ? 100
    : (noIncludedNoShowOrLateHandling / Number(shippedUnits)) * 100;
};

export const noExcludedBookings = (bookings: RoutePairBookings) => {
  let noExcluded = 0;
  bookings.forEach((booking) => {
    if (
      !(
        booking.handlingStatus === BookingStatusOptions.TOINVOICE ||
        booking.handlingStatus === BookingStatusOptions.TODO ||
        booking.handlingStatus === BookingStatusOptions.INVOICED ||
        booking.handlingStatus === BookingStatusOptions.THRESHOLD
      )
    ) {
      noExcluded++;
    }
  });

  return noExcluded;
};

export const getConsignmentNo = (routePairsData: RoutePairData[]) => {
  if (routePairsData.length > 0) {
    for (let routePairData of routePairsData) {
      if (routePairData.bookings.length > 0) {
        return routePairData.bookings[0].consignmentNo;
      }
    }
  }
  return null;
};
