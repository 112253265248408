import React from "react";
import { ResultListBanner } from "@stenajs-webui/elements";
import { FreightPricingPortalError } from "../models/FreightPricingPortalError";
import { transformFreightPricingPortalErrorsToResultListBannerState } from "../transformers/TransformFreightPricingPortalErrorsToResultListBannerState";

interface Props {
  error: FreightPricingPortalError;
  heading?: string;
}

export const ErrorDisplayBanner: React.FC<Props> = ({ error, heading }) => {
  const bannerState =
    transformFreightPricingPortalErrorsToResultListBannerState(error);
  bannerState.headerText = heading ?? "Something went wrong, please try again";
  return <ResultListBanner variant={"error"} bannerState={bannerState} />;
};
