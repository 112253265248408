import { Indent, Txt } from "@stenajs-webui/core";
import { createColumnConfig, StandardTableConfig } from "@stenajs-webui/grid";
import {
  ViewSpecificationLateHandlingRouteBookingTableColumnKeys,
  ViewSpecificationNoShowRouteBookingTableColumnKeys,
} from "./RowsAndColumnsConfig";
import { RoutePairBooking } from "../transformers/InvoiceSpecificationDataToHandlingTypeDataTransformer";
import { format, parseISO } from "date-fns";
import { BookingStatusOptions } from "@/common/collections/HandlingBookingStatuses";
import { formatHoursMinutesWithColon } from "@/common/formatters/DateFormatter";
import {
  firstLetterCapital,
  handleUserName,
} from "@/common/string/GeneralStringFormatting";
import { NoShowLateHandlingTypes } from "@/common/types/NoShowLateHandlingTypes";

const getStatus = (value: string | null) => {
  if (
    value === BookingStatusOptions.TOINVOICE ||
    value === BookingStatusOptions.TODO
  ) {
    return "Pending";
  }
  if (value === BookingStatusOptions.THRESHOLD) {
    return "Threshold";
  }
  if (value !== BookingStatusOptions.INVOICED) {
    return "Excluded";
  }

  return firstLetterCapital(value ?? "");
};

export const noShowRouteBookingTableConfig: StandardTableConfig<
  RoutePairBooking,
  ViewSpecificationNoShowRouteBookingTableColumnKeys
> = {
  keyResolver: (item) => String(item.id),
  disableSorting: false,
  // disableInfiniteList needs to be true for the css print to work
  disableInfiniteList: true,
  columns: {
    bookingNo: createColumnConfig((item) => item.bookingNo, {
      columnLabel: "Booking no.",
    }),
    handlingStatus: createColumnConfig((item) => item.handlingStatus, {
      columnLabel: "Status",
      itemLabelFormatter: (value, item) => {
        return getStatus(value);
      },
    }),
    routeCode: createColumnConfig((item) => item.routeCode, {
      columnLabel: "Route",
    }),
    departureDate: createColumnConfig((item) => item.departureDate, {
      columnLabel: "Dep. date",
    }),
    departureTime: createColumnConfig((item) => item.departureTime, {
      columnLabel: "Dep. time",
      renderCell: ({ label, value, item }) => {
        return (
          <Indent>
            <Txt>
              {item.departureTime &&
                formatHoursMinutesWithColon(item.departureTime)}
            </Txt>
          </Indent>
        );
      },
    }),
    vehicleType: createColumnConfig(
      (item) => firstLetterCapital(item.vehicleType ?? ""),
      {
        columnLabel: "Vehicle",
      }
    ),
    vehicleReg: createColumnConfig((item) => item.vehicleReg, {
      columnLabel: "Vehicle no.",
    }),
    trailerReg: createColumnConfig((item) => item.trailerReg, {
      columnLabel: "Trailer reg.",
    }),
    customerReference: createColumnConfig((item) => item.customerReference, {
      columnLabel: "Cust. ref.",
    }),
  },
  columnOrder: [
    "bookingNo",
    "handlingStatus",
    "routeCode",
    "departureDate",
    "departureTime",
    "vehicleType",
    "vehicleReg",
    "trailerReg",
    "customerReference",
  ],
};

export const lateHandlingRouteBookingTableConfig: StandardTableConfig<
  RoutePairBooking,
  ViewSpecificationLateHandlingRouteBookingTableColumnKeys
> = {
  keyResolver: (item) => String(item.id),
  disableSorting: false,
  // disableInfiniteList needs to be true for the css print to work
  disableInfiniteList: true,
  columns: {
    bookingNo: createColumnConfig((item) => item.bookingNo, {
      columnLabel: "Booking no.",
    }),
    handlingType: createColumnConfig((item) => item.handlingType, {
      columnLabel: "Type",
      itemLabelFormatter: (value, item) => {
        if (value === NoShowLateHandlingTypes.LATECANCEL) {
          return "Cancel";
        }
        if (value === NoShowLateHandlingTypes.LATECHANGE) {
          return "Transfer";
        }

        return firstLetterCapital(value ?? "");
      },
    }),
    routeCode: createColumnConfig((item) => item.routeCode, {
      columnLabel: "Route",
    }),
    handlingStatus: createColumnConfig((item) => item.handlingStatus, {
      columnLabel: "Status",
      itemLabelFormatter: (value, item) => {
        return getStatus(value);
      },
    }),
    departureDate: createColumnConfig((item) => item.departureDate, {
      columnLabel: "Dep. date",
    }),
    departureTime: createColumnConfig(
      (item) =>
        item.departureTime && formatHoursMinutesWithColon(item.departureTime),
      {
        columnLabel: "Dep. time",
        renderCell: ({ label, value, item }) => {
          return (
            <Indent>
              <Txt>
                {item.departureTime &&
                  formatHoursMinutesWithColon(item.departureTime)}
              </Txt>
            </Indent>
          );
        },
      }
    ),
    lateHandlingDate: createColumnConfig((item) => item.lateHandlingDate, {
      columnLabel: "Cancel/Transfer date",
      itemLabelFormatter: (value, item) => {
        return value ? format(parseISO(value), "yyyy-MM-dd, HH:mm") : "";
      },
    }),
    lateHandlingUser: createColumnConfig(
      (item) => handleUserName(item.lateHandlingUser),
      {
        columnLabel: "User",
      }
    ),
    vehicleType: createColumnConfig(
      (item) => firstLetterCapital(item.vehicleType ?? ""),
      {
        columnLabel: "Vehicle",
      }
    ),
    vehicleReg: createColumnConfig((item) => item.vehicleReg, {
      columnLabel: "Vehicle no.",
    }),
    trailerReg: createColumnConfig((item) => item.trailerReg, {
      columnLabel: "Trailer reg.",
    }),
    customerReference: createColumnConfig((item) => item.customerReference, {
      columnLabel: "Cust. ref.",
    }),
  },
  columnOrder: [
    "bookingNo",
    "handlingType",
    "routeCode",
    "handlingStatus",
    "departureDate",
    "departureTime",
    "lateHandlingDate",
    "lateHandlingUser",
    "vehicleType",
    "vehicleReg",
    "trailerReg",
    "customerReference",
  ],
};
