export enum IndentValues {
  FOUR = 0.5,
  EIGHT = 1,
  TWELVE = 1.5,
  SIXTEEN = 2,
  TWENTY = 2.5,
  TWENTYFOUR = 3,
  TWENTYEIGHT = 3.5,
  THIRTYTWO = 4,
  THIRTYSIX = 4.5,
  FORTY = 5,
}
