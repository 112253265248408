import * as React from "react";
import { NumericTextInput } from "../../numeric-text-input/NumericTextInput";
import { FlatButton, stenaTimes } from "@stenajs-webui/elements";

interface Props {
  invoiceNumber: string;
  isInternalView?: boolean;
  setInvoiceNumber: (invoiceNo: string) => void;
  handleSearchByInvoiceNumber?: (invoiceNumber: string) => void;
}

export const InvoiceSpecificationInvoiceNumberInput: React.FC<Props> = ({
  invoiceNumber,
  isInternalView,
  setInvoiceNumber,
  handleSearchByInvoiceNumber,
}) => {
  return (
    <NumericTextInput
      maxNumbers={9}
      maxDecimals={0}
      value={invoiceNumber}
      onValueChange={(value) => setInvoiceNumber(value)}
      buttonRight={
        invoiceNumber.length > 0 && (
          <FlatButton
            onClick={() => setInvoiceNumber("")}
            rightIcon={stenaTimes}
          />
        )
      }
      onEnter={() =>
        isInternalView &&
        handleSearchByInvoiceNumber &&
        handleSearchByInvoiceNumber(invoiceNumber)
      }
    />
  );
};
