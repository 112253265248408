import React, { useState } from "react";
import { ErrorDisplayModal } from "./ErrorDisplayModal";
import { ErrorDisplayBanner } from "./ErrorDisplayBanner";
import { FreightPricingPortalError } from "../models/FreightPricingPortalError";
import { HttpErrors } from "../constants";

const handledErrors = [
  HttpErrors.UnprocessableEntity,
  HttpErrors.BadRequest,
  HttpErrors.NotFound,
];

interface Props {
  error: FreightPricingPortalError | Error;
  heading?: string;
  noModal?: boolean;
  onClose?: () => void;
}

export const ErrorDisplay: React.FC<Props> = ({
  error,
  heading,
  onClose,
  noModal = false,
}) => {
  const [visibleModal, setVisibleModal] = useState(true);

  const handleOnCloseModal = () => {
    setVisibleModal(false);
    onClose?.();
  };

  if (!("statusCode" in error)) {
    return (
      <ErrorDisplayModal
        error={{
          statusCode: 500,
          message: null,
          description: "Could not handle error",
          errors: [
            {
              message:
                "Error from gql that could not be handled. " + error.message,
              path: null,
            },
          ],
        }}
        onClose={handleOnCloseModal}
        visible={visibleModal}
      />
    );
  }

  if (!error) {
    return null;
  }
  if (
    (error.statusCode && handledErrors.includes(error.statusCode)) ||
    noModal
  ) {
    return <ErrorDisplayBanner error={error} heading={heading} />;
  }

  return (
    <ErrorDisplayModal
      error={error}
      onClose={handleOnCloseModal}
      visible={visibleModal}
    />
  );
};
