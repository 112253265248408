export enum BookingStatusOptions {
  DONOTINV = "DONOTINV",
  TODO = "TODO",
  TOINVOICE = "TOINVOICE",
  NOTCHARGE = "NOTCHARGE",
  INVOICED = "INVOICED",
  THRESHOLD = "THRESHOLD",
}
export interface SelectBookingStatusOption {
  value: BookingStatusOptions;
  label: string;
}

export const selectBookingStatusOptions: Array<SelectBookingStatusOption> = [
  {
    value: BookingStatusOptions.TODO,
    label: "Todo",
  },
  {
    value: BookingStatusOptions.TOINVOICE,
    label: "To be invoiced (TBI)",
  },
  {
    value: BookingStatusOptions.DONOTINV,
    label: "Do not invoice (DNI)",
  },
];
