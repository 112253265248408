export const formatDateString = (date: Date) => {
  const offset = date.getTimezoneOffset() * 60000;
  const utcDate = new Date(date.getTime() - offset);

  return utcDate.toISOString().substr(0, 10);
};

export const stripDateString = (date: string) => {
  return date.substr(0, 10);
};

export const stripYearMonthString = (date: string) => {
  return date.substr(0, 7);
};

export const formatMs = (duration: number) => {
  if (duration > 1000) {
    return duration / 1000 + "s";
  } else {
    return duration + "ms";
  }
};

export const formatDateStringWithLocaleAndTimeZone = ({
  dateString,
  locale,
  options,
}: {
  dateString: string | undefined;
  locale?: string;
  options?: Intl.DateTimeFormatOptions;
}) =>
  dateString
    ? new Intl.DateTimeFormat(locale ?? navigator.language, options).format(
        new Date(dateString.replace("Z", ""))
      )
    : "";

export const formatDateStringWithSwedishTimeZone = ({
  dateString,
  locale,
  options,
}: {
  dateString: string | undefined;
  locale?: string;
  options?: Intl.DateTimeFormatOptions;
}) =>
  formatDateStringWithLocaleAndTimeZone({
    dateString,
    locale,
    options: { ...options, timeZone: "Europe/Stockholm" },
  });

export const formatDateStringRangeWithLocaleAndTimeZone = ({
  dateStringFrom,
  dateStringTo,
  optionsFrom,
  optionsTo,
  locale,
}: {
  dateStringFrom: string | undefined;
  dateStringTo: string | undefined;
  optionsFrom?: Intl.DateTimeFormatOptions;
  optionsTo?: Intl.DateTimeFormatOptions;
  locale?: string;
}) => {
  if (dateStringFrom && !dateStringTo) {
    return new Intl.DateTimeFormat(
      locale ?? navigator.language,
      optionsFrom
    ).format(new Date(dateStringFrom.replace("Z", "")));
  } else if (!dateStringFrom && dateStringTo) {
    return new Intl.DateTimeFormat(
      locale ?? navigator.language,
      optionsTo
    ).format(new Date(dateStringTo.replace("Z", "")));
  } else
    return (
      (dateStringFrom &&
        new Intl.DateTimeFormat(
          locale ?? navigator.language,
          optionsFrom
        ).format(new Date(dateStringFrom.replace("Z", "")))) +
      " - " +
      (dateStringTo &&
        new Intl.DateTimeFormat(locale ?? navigator.language, optionsTo).format(
          new Date(dateStringTo.replace("Z", ""))
        ))
    );
};

export const formatDateStringRangeWithSwedishTimeZone = ({
  dateStringFrom,
  dateStringTo,
  optionsFrom,
  optionsTo,
  locale,
}: {
  dateStringFrom: string | undefined;
  dateStringTo: string | undefined;
  optionsFrom?: Intl.DateTimeFormatOptions;
  optionsTo?: Intl.DateTimeFormatOptions;
  locale?: string;
}) =>
  formatDateStringRangeWithLocaleAndTimeZone({
    dateStringFrom,
    dateStringTo,
    locale,
    optionsFrom: { ...optionsFrom, timeZone: "Europe/Stockholm" },
    optionsTo: { ...optionsTo, timeZone: "Europe/Stockholm" },
  });

export const formatDateWithDashOnInput = (
  dateInput: string,
  prevLength: number
) => {
  if (dateInput.length === 1 && isNaN(Number(dateInput))) {
    return "";
  }

  if (isNaN(Number(dateInput.slice(-1)))) {
    return dateInput.slice(0, -1);
  }

  if (
    (dateInput.length === 9 && Number(dateInput.slice(-1)) > 3) ||
    (dateInput.length === 8 &&
      prevLength === 7 &&
      Number(dateInput.slice(7, 8)) > 3)
  ) {
    return dateInput.slice(0, -1);
  }

  if (
    (dateInput.length === 6 && Number(dateInput.slice(5, 6)) > 1) ||
    (dateInput.length === 7 &&
      Number(dateInput.slice(5, 6)) === 1 &&
      Number(dateInput.slice(6, 7)) > 2) ||
    (dateInput.length === 7 &&
      Number(dateInput.slice(5, 6)) === 0 &&
      Number(dateInput.slice(6, 7)) === 0) ||
    (dateInput.length === 5 &&
      prevLength === 4 &&
      Number(dateInput.slice(4, 5)) > 1)
  ) {
    return dateInput.slice(0, -1);
  }

  if (
    dateInput.length === 10 &&
    Number(dateInput.slice(-2, -1)) === 3 &&
    Number(dateInput.slice(-1)) > 1
  ) {
    return dateInput.slice(0, -1);
  }

  let output = dateInput;

  if (prevLength === 7 && dateInput.length > 6) {
    const lastChar = dateInput.slice(-1);
    output = output.slice(0, -1) + "-" + lastChar;
  }

  if (prevLength === 4 && dateInput.length === 5) {
    const lastChar = dateInput.slice(-1);
    output = output.slice(0, -1) + "-" + lastChar;
  }

  if (dateInput.length === 4 && prevLength !== 5) {
    output += "-";
  }

  if (dateInput.length === 7 && prevLength !== 8) {
    output += "-";
  }

  if (dateInput.length > 10) {
    output = output.slice(0, -1);
  }

  return output;
};

export const addZeroToMonth = (month: number | string) => {
  return month && Number(month) <= 9 ? "0" + month : month ?? "";
};

export const formatYearAndMonthWithDashOnInput = (
  dateInput: string,
  ref: HTMLInputElement
) => {
  if (dateInput.length === 1 && isNaN(Number(dateInput))) {
    return "";
  }

  if (isNaN(Number(dateInput.slice(-1)))) {
    return dateInput.slice(0, -1);
  }

  if (
    ref.selectionStart === 5 &&
    dateInput.length === 6 &&
    dateInput.includes("-") &&
    Number(dateInput.slice(5, 6)) > 1
  ) {
    return dateInput.slice(0, -1);
  } else if (
    (dateInput.length === 6 &&
      ref.selectionStart === 6 &&
      Number(dateInput.slice(5, 6)) > 1) ||
    (dateInput.length === 7 &&
      Number(dateInput.slice(5, 6)) === 1 &&
      Number(dateInput.slice(6, 7)) > 2) ||
    (dateInput.length === 7 &&
      Number(dateInput.slice(5, 6)) === 0 &&
      Number(dateInput.slice(6, 7)) === 0)
  ) {
    return dateInput.slice(0, -1);
  }
  if (
    (dateInput.length === 6 &&
      ref.selectionStart === 6 &&
      Number(dateInput.slice(5, 6)) > 1) ||
    (dateInput.length === 7 &&
      Number(dateInput.slice(5, 6)) === 1 &&
      Number(dateInput.slice(6, 7)) > 2) ||
    (dateInput.length === 7 &&
      Number(dateInput.slice(5, 6)) === 0 &&
      Number(dateInput.slice(6, 7)) === 0)
  ) {
    return dateInput.slice(0, -1);
  }

  let output = dateInput;

  if (dateInput.length === 4 && !dateInput.includes("-")) {
    output += "-";
  }

  return output;
};
