import { SectionalRouteAgreements } from "@/features/product-and-price/head-agreement/activate/components/ActivateHeadAgreementContainer";
import { ResultItem, ResultListBannerState } from "@stenajs-webui/elements";
import { FreightPricingPortalError } from "../models/FreightPricingPortalError";
import { extractInfoFromPath, routeFactory } from "../../../RouteFactory";
import { NavigateFunction } from "react-router-dom";

export const transformFreightPricingPortalErrorsToResultListBannerState = (
  error: FreightPricingPortalError,
  customerId?: string,
  headAgreementId?: string,
  navigate?: NavigateFunction,
  sectionRouteAgreements?: SectionalRouteAgreements
): ResultListBannerState => {
  const resultItems = error.errors.map<ResultItem>((errorDetail) => {
    const pathInfo = extractInfoFromPath(errorDetail.path ?? undefined);
    if (pathInfo) {
      return {
        text: errorDetail.message,
        linkText: pathInfo.agrRouteNumber
          ? pathInfo.agrRouteNumber
          : pathInfo.agreementNumber,
        onClickLink: () =>
          customerId &&
          headAgreementId &&
          sectionRouteAgreements &&
          navigate &&
          navigate(
            routeFactory.productAndPrice.linkToErroredResource(
              customerId,
              headAgreementId,
              sectionRouteAgreements,
              errorDetail.path ?? ""
            )
          ),
      };
    }
    return {
      text: errorDetail.message,
    };
  });
  return {
    text: "Failed with the following errors: ",
    items: resultItems,
  };
};
