import { BoxProps, Row, TextProps, Txt } from "@stenajs-webui/core";
import { cssColor } from "@stenajs-webui/theme";
import * as React from "react";

export interface LeftContentProps {
  leftContent?: string | number | React.ReactNode | null | undefined;
  leftWidth?: BoxProps["width"];
  leftMinWidth?: BoxProps["minWidth"];
  leftJustifyContent?: BoxProps["justifyContent"];
  leftAlignItems?: BoxProps["alignItems"];
  leftFlexDirection?: BoxProps["flexDirection"];
  leftTextVariant?: TextProps["variant"];
  leftTextColor?: TextProps["color"];
  leftTextSize?: TextProps["size"];
  leftTextWhiteSpace?: TextProps["whiteSpace"];
  leftTextWordBreak?: TextProps["wordBreak"];
  leftContentUseCustomComponent?: boolean;
}

export const LeftContent: React.FC<LeftContentProps> = ({
  leftContent,
  leftWidth,
  leftMinWidth,
  leftJustifyContent,
  leftAlignItems,
  leftFlexDirection,
  leftContentUseCustomComponent,
  leftTextWordBreak,
  leftTextVariant,
  leftTextColor = cssColor("--lhds-color-ui-600"),
  leftTextSize = "small",
  leftTextWhiteSpace,
}) => {
  return (
    <Row
      width={leftWidth}
      minWidth={leftMinWidth}
      justifyContent={leftJustifyContent}
      alignItems={leftAlignItems}
      flexDirection={leftFlexDirection}
    >
      {leftContentUseCustomComponent ? (
        leftContent
      ) : (
        <Txt
          color={leftTextColor}
          variant={leftTextVariant}
          size={leftTextSize}
          wordBreak={leftTextWordBreak}
          whiteSpace={leftTextWhiteSpace}
        >
          {leftContent}
        </Txt>
      )}
    </Row>
  );
};
