import { useWindowSize } from "@/common/hooks/UseWindowSize";
import { NoShowLateHandlingTypes } from "@/common/types/NoShowLateHandlingTypes";
import { IndentValues } from "@/common/utils/IndentValues";
import { SpacingValues } from "@/common/utils/SpacingValues";
import { Indent, Row, SeparatorLine, Spacing } from "@stenajs-webui/core";
import { Card } from "@stenajs-webui/elements";
import { cssColor } from "@stenajs-webui/theme";
import * as React from "react";
import { AccountSpecificationCard } from "../../invoice-specification-cards/AccountSpecificationCard";
import { OneMonthBookings } from "../models/OneMonthBookings";
import { invoiceSpecificationDataToHandlingTypeDataTransformer } from "../transformers/InvoiceSpecificationDataToHandlingTypeDataTransformer";
import { OneMonthBookingsLateHandlingSummaryAndRoutePairTableWrapper } from "./OneMonthBookingsLateHandlingSummaryAndRoutePairTableWrapper";
import { OneMothBookingsNoShowSummaryAndRoutePairTableWrapper } from "./OneMothBookingsNoShowSummaryAndRoutePairTableWrapper";

interface Props {
  oneMonthBookingsData: OneMonthBookings;
}

export const OneMonthBookingsView: React.FC<Props> = ({
  oneMonthBookingsData,
}) => {
  const { width: windowWidth } = useWindowSize();
  const smallWindowWidth = windowWidth <= 840;
  const groupedHandlingTypeData =
    invoiceSpecificationDataToHandlingTypeDataTransformer(oneMonthBookingsData);
  return (
    <>
      <Indent
        num={IndentValues.TWENTYFOUR}
        className={"print-background-color"}
      >
        <Spacing num={SpacingValues.TWENTYFOUR}>
          <Row width={smallWindowWidth ? "100vw" : undefined}>
            <AccountSpecificationCard
              accountName={oneMonthBookingsData.custName ?? ""}
              accountNumber={oneMonthBookingsData.custNo}
              accountIndex={oneMonthBookingsData.custIndex ?? ""}
              noShowThreshold={String(
                oneMonthBookingsData.thresholdNoShowPercentage
              )}
              lateHandlingThreshold={String(
                oneMonthBookingsData.thresholdLateHandlingPercentage
              )}
            />
          </Row>
        </Spacing>
        <Card className="card-table-wrapper">
          {
            // This div needs to be here for the css print to work correctly
          }
          <div>
            {groupedHandlingTypeData.map((handlingTypeData) => {
              return (
                <React.Fragment
                  key={`one-month-bookings-${handlingTypeData.handlingType}-route-pair-table-wrapper`}
                >
                  {handlingTypeData.handlingType ===
                    NoShowLateHandlingTypes.NOSHOW && (
                    <>
                      <OneMothBookingsNoShowSummaryAndRoutePairTableWrapper
                        routePairsData={handlingTypeData.routePairData}
                        key={handlingTypeData.handlingType}
                      />
                      {groupedHandlingTypeData.length > 1 && (
                        <>
                          <SeparatorLine
                            width="0.5px"
                            color={cssColor("--lhds-color-ui-300")}
                            key={
                              handlingTypeData.handlingType + "-separatorLine"
                            }
                          />
                          <Spacing
                            num={SpacingValues.TWELVE}
                            key={handlingTypeData.handlingType + "-spacing"}
                          />
                        </>
                      )}
                    </>
                  )}

                  {handlingTypeData.handlingType ===
                    NoShowLateHandlingTypes.LATE_HANDL && (
                    <OneMonthBookingsLateHandlingSummaryAndRoutePairTableWrapper
                      routePairsData={handlingTypeData.routePairData}
                      key={handlingTypeData.handlingType}
                    />
                  )}
                </React.Fragment>
              );
            })}
          </div>
        </Card>
      </Indent>
    </>
  );
};
