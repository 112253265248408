import { BookingStatusOptions } from "@/common/collections/HandlingBookingStatuses";
import { groupBy } from "lodash";
import {
  InvoiceSpecificationBooking,
  InvoiceSpecification,
} from "../models/InvoiceSpecification";
import { noExcludedBookings } from "../utils/utils";

const months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

export type RoutePairBookings = InvoiceSpecificationBooking[];
export type RoutePairBooking = InvoiceSpecificationBooking;

export type RoutePairSummary = {
  invoiceCurrencyCode: string | null;
  handlingType: string | null;
  month: string;
  shippedUnits: number;
  custNo: number;
  routePairCode: string | null;
  noOfNoShowLateHandling: number;
  noExcludedBookings: number;
  invoiced: number;
  threshold: number;
  dni: number;
  total: number;
  routePairDescription: string | null;
};

export interface RoutePairData {
  bookings: RoutePairBookings;
  summary: RoutePairSummary;
}

export interface HandlingTypeData {
  handlingType: string;
  routePairData: Array<RoutePairData>;
}

export const invoiceSpecificationDataToHandlingTypeDataTransformer = (
  invoiceSpecificationData: InvoiceSpecification,
  showExcludedBookings: boolean
): HandlingTypeData[] => {
  const groupedByHandlingType = groupBy(
    invoiceSpecificationData.nslhBookings,
    (booking) => booking.handlingTypeGrouped
  );
  return Object.keys(groupedByHandlingType).map((handlingType) => {
    const handlingTypeBookings = groupedByHandlingType[handlingType];

    const groupedByRoutePairCode = groupBy(
      handlingTypeBookings,
      (i) => i.routePairCode
    );

    return {
      handlingType: handlingType,
      routePairData: Object.keys(groupedByRoutePairCode).map(
        (routePairCode) => {
          const routePairBookings = groupedByRoutePairCode[routePairCode];
          const routePairVolume =
            invoiceSpecificationData.routePairVolumes.find(
              (rpv) => rpv.routePairCode === routePairCode
            );
          const shippedVolume = routePairVolume?.shippedVolume ?? 0;

          return {
            bookings: showExcludedBookings
              ? routePairBookings
              : routePairBookings.filter(
                  (rpb) =>
                    rpb.handlingStatus === BookingStatusOptions.INVOICED ||
                    rpb.handlingStatus === BookingStatusOptions.THRESHOLD
                ),
            summary: {
              invoiceCurrencyCode: invoiceSpecificationData.invoiceCurrencyCode,
              noExcludedBookings: noExcludedBookings(routePairBookings),
              handlingType: handlingType,
              month: `${
                months[invoiceSpecificationData.nslhMonth - 1]
              }-${String(invoiceSpecificationData.nslhYear).substring(2, 4)}`,
              custNo: invoiceSpecificationData.custNo,
              routePairCode: routePairBookings[0].routePairCode,
              shippedUnits: shippedVolume,
              noOfNoShowLateHandling: routePairBookings.length,
              invoiced: routePairBookings.filter(
                (rpb) => rpb.handlingStatus === BookingStatusOptions.INVOICED
              ).length,
              threshold: routePairBookings.filter(
                (rpb) => rpb.handlingStatus === BookingStatusOptions.THRESHOLD
              ).length,
              dni: routePairBookings.filter(
                (rpb) => rpb.handlingStatus === BookingStatusOptions.DONOTINV
              ).length,
              total: routePairBookings.length,
              routePairDescription: routePairBookings[0].routePairDescription,
            },
          };
        }
      ),
    };
  });
};
