import {
  verifyNumericTextIsNumeric,
  cleanNumericTextFieldInput,
} from "./formatters/NumericTextFieldInputCleaner";

export const formatPercent = (
  valueInDecimal: number,
  maximumFractionDigits: number = 1,
  includePercentSign?: boolean,
  minimumFractionDigits?: number
): string => {
  const value = valueInDecimal * 100;

  const percentFormatter = new Intl.NumberFormat("sv-SE", {
    maximumFractionDigits,
    minimumFractionDigits: minimumFractionDigits ? minimumFractionDigits : 0,
  });

  return percentFormatter.format(value) + (includePercentSign ? "%" : "");
};

export const formatPercentWithSign = (
  valueInDecimal: number,
  maximumFractionDigits: number = 1,
  includePercentSign?: boolean,
  minimumFractionDigits?: number
): string => {
  const prefix = valueInDecimal > 0 ? "+" : valueInDecimal < 0 ? "" : "±";

  return (
    prefix +
    formatPercent(
      valueInDecimal,
      maximumFractionDigits,
      includePercentSign,
      minimumFractionDigits
    )
  );
};

export const formatThousands = (value: number) => {
  const formatter = new Intl.NumberFormat("sv-SE", {
    useGrouping: true,
  });
  return formatter.format(value);
};

export const formatThousandsAndDecimalsWithNullAndUndefinedValuesAccepted = (
  value: number | undefined | null,
  numDecimals: number = 2
) => {
  if (!value) {
    return "";
  }

  const formatter = new Intl.NumberFormat("sv-SE", {
    useGrouping: true,
    maximumFractionDigits: numDecimals,
  });

  let formattedValue = formatter.format(value);

  if (!formattedValue.includes(",") && numDecimals > 0) {
    formattedValue += ",";
    Array.from(Array(numDecimals).keys()).forEach(() => (formattedValue += 0));
  }

  if (
    formattedValue.includes(",") &&
    formattedValue.length - formattedValue.indexOf(",") - 1 < numDecimals
  ) {
    const missingDecimals =
      numDecimals - (formattedValue.length - formattedValue.indexOf(",") - 1);

    Array.from(Array(missingDecimals).keys()).forEach(
      () => (formattedValue += 0)
    );
  }

  return formattedValue;
};

export const formatThousandsAndDecimals = (
  value: number,
  numDecimals: number = 2
) => {
  const formatter = new Intl.NumberFormat("sv-SE", {
    useGrouping: true,
    maximumFractionDigits: numDecimals,
  });

  return formatter.format(value);
};

export const formatThousandsAndDecimalsToPercentage = (
  value: number,
  numDecimals: number = 2
) => {
  const formatter = new Intl.NumberFormat("sv-SE", {
    useGrouping: true,
    maximumFractionDigits: numDecimals,
  });
  return formatter.format(value * 100);
};

export const formatThousandsCheckNull = (
  value: number | null | undefined
): string => (!value ? "-" : formatThousands(value));

export const parseFloatElseThrow = (s: string): number => {
  const r = parseFloatElseUndefined(
    verifyNumericTextIsNumeric(cleanNumericTextFieldInput(s))
  );
  if (r === undefined) {
    throw new Error(`Numeric value "${s}" is not valid.`);
  }
  return r;
};

export const parseFloatElseUndefined = (s: string): number | undefined => {
  try {
    const f = parseFloat(s);
    if (isNaN(f)) {
      return undefined;
    }
    if (f == null) {
      return undefined;
    }
    return f;
  } catch (e) {}
  return undefined;
};

export const parseIntElseUndefined = (s: string): number | undefined => {
  try {
    const f = parseInt(s);
    if (isNaN(f)) {
      return undefined;
    }
    if (f == null) {
      return undefined;
    }
    return f;
  } catch (e) {}
  return undefined;
};

export const toStringElseEmptyString = (
  n: number | undefined | null
): string => {
  if (n == null) {
    return "";
  }
  return String(n);
};

export const convertEditorValueToValidInput = (input: string): number => {
  if (input.includes(",")) {
    const updatedString = input.replace(",", ".");
    return parseFloat(updatedString);
  }

  return parseFloat(input);
};
