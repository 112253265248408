export const cleanNumericTextFieldInput = (s: string): string => {
  return s.replace(/ /g, "").replace(/,/g, ".");
};

export const removeTrailingCommaOrDot = (s: string): string => {
  return s.replace(/[,.]$/, "");
};

export const dotToCommaTransform = (s: string): string => {
  return s.replace(".", ",");
};

export const commaToDotTransform = (s: string): string => {
  return s.replace(",", ".");
};

export const verifyNumericTextIsNumeric = (s: string): string => {
  const r = s.match(/[^0-9$.,]/g);
  if (r && r.length) {
    throw new Error(`Numeric value "${s}" is not valid.`);
  }
  return s;
};

export const formatThousandsFromStringOrNumber = (
  value: string | undefined | null | number
): string => {
  let output = value ?? "";

  if (Number(value) > 999 && String(value)) {
    output =
      String(value).substring(0, 1) +
      " " +
      String(value).substring(1, String(value).length);
  }

  return String(output);
};
