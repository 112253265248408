import {
  Card,
  CardBody,
  CardHeader,
  Icon,
  stenaBusinessInvoice,
} from "@stenajs-webui/elements";
import { cssColor } from "@stenajs-webui/theme";
import * as React from "react";
import { InfoPair } from "../info-pair/InfoPair";

interface Props {
  accountName: string;
  accountNumber: number | undefined;
  accountIndex: string;
  noShowThreshold: string;
  lateHandlingThreshold: string;
}

export const AccountSpecificationCard: React.FC<Props> = ({
  accountName,
  accountNumber,
  accountIndex,
  noShowThreshold,
  lateHandlingThreshold,
}) => (
  <Card>
    <CardHeader
      variant={"compact"}
      text={"Account"}
      contentLeft={
        <Icon
          icon={stenaBusinessInvoice}
          size={16}
          color={cssColor("--lhds-color-ui-700")}
        />
      }
    />
    <CardBody flexDirection={"row"} flexWrap={"wrap"}>
      <InfoPair
        leftContent={"Name"}
        leftTextColor={cssColor("--lhds-color-ui-500")}
        leftTextSize={"small"}
        leftTextWhiteSpace={"nowrap"}
        rightTextSize={"small"}
        rightTextWhiteSpace={"nowrap"}
        rightTextColor={cssColor("--lhds-color-ui-700")}
        indentAfterComponent={1.5}
        borderBottom={false}
        rightContent={accountName}
      />
      <InfoPair
        leftContent={"Index"}
        leftTextColor={cssColor("--lhds-color-ui-500")}
        leftTextSize={"small"}
        leftTextWhiteSpace={"nowrap"}
        rightTextSize={"small"}
        rightTextWhiteSpace={"nowrap"}
        rightTextColor={cssColor("--lhds-color-ui-700")}
        indentAfterComponent={1.5}
        borderBottom={false}
        rightContent={accountIndex}
      />

      <InfoPair
        leftContent={"Number"}
        leftTextColor={cssColor("--lhds-color-ui-500")}
        leftTextSize={"small"}
        leftTextWhiteSpace={"nowrap"}
        rightTextSize={"small"}
        rightTextWhiteSpace={"nowrap"}
        rightTextColor={cssColor("--lhds-color-ui-700")}
        indentAfterComponent={1.5}
        borderBottom={false}
        rightContent={accountNumber}
      />

      <InfoPair
        leftContent={"No show threshold"}
        leftTextColor={cssColor("--lhds-color-ui-500")}
        leftTextSize={"small"}
        leftTextWhiteSpace={"nowrap"}
        rightTextSize={"small"}
        rightTextWhiteSpace={"nowrap"}
        rightTextColor={cssColor("--lhds-color-ui-700")}
        indentAfterComponent={1.5}
        borderBottom={false}
        rightContent={
          noShowThreshold !== "null" ? noShowThreshold + "%" : "N/A"
        }
      />
      <InfoPair
        leftContent={"Late handling threshold"}
        leftTextColor={cssColor("--lhds-color-ui-500")}
        leftTextSize={"small"}
        leftTextWhiteSpace={"nowrap"}
        rightTextSize={"small"}
        rightTextWhiteSpace={"nowrap"}
        rightTextColor={cssColor("--lhds-color-ui-700")}
        indentAfterComponent={1.5}
        borderBottom={false}
        rightContent={
          lateHandlingThreshold !== "null" ? lateHandlingThreshold + "%" : "N/A"
        }
      />
    </CardBody>
  </Card>
);
