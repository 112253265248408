import { BoxProps, Row, TextProps, Txt } from "@stenajs-webui/core";
import { InputSpinner } from "@stenajs-webui/elements";
import { ErrorPanel } from "@stenajs-webui/panels";
import { cssColor } from "@stenajs-webui/theme";
import { ApolloError } from "apollo-boost";
import * as React from "react";
import { ReactNode } from "react";

export interface RightContentProps {
  rightContent: string | number | ReactNode | null | undefined;
  rightWidth?: BoxProps["width"];
  rightMinWidth?: BoxProps["minWidth"];
  rightJustifyContent?: BoxProps["justifyContent"];
  rightAlignItems?: BoxProps["alignItems"];
  rightFlexDirection?: BoxProps["flexDirection"];
  rightContentUseCustomComponent?: boolean;
  rightTextVariant?: TextProps["variant"];
  rightTextColor?: TextProps["color"];
  rightTextSize?: TextProps["size"];
  rightTextWhiteSpace?: TextProps["whiteSpace"];
  rightTextWordBreak?: TextProps["wordBreak"];
  loading?: boolean;
  error?: ApolloError;
}

export const RightContent: React.FC<RightContentProps> = ({
  rightContent,
  rightWidth,
  rightMinWidth,
  rightJustifyContent,
  rightAlignItems,
  rightFlexDirection,
  rightContentUseCustomComponent,
  rightTextVariant,
  rightTextWordBreak,
  rightTextColor = cssColor("--lhds-color-ui-900"),
  rightTextSize = "small",
  rightTextWhiteSpace,

  loading,
  error,
}) => {
  const handleRightContent = () => {
    if (loading) {
      return <InputSpinner size={"small"} />;
    }
    if (error) {
      return <ErrorPanel text={error?.message} />;
    }
    if (!loading && !error) {
      return rightContent;
    }
  };

  return (
    <Row
      width={rightWidth}
      minWidth={rightMinWidth}
      justifyContent={rightJustifyContent}
      alignItems={rightAlignItems}
      flexDirection={rightFlexDirection}
    >
      {rightContentUseCustomComponent ? (
        handleRightContent()
      ) : (
        <Txt
          variant={rightTextVariant}
          color={rightTextColor}
          size={rightTextSize}
          wordBreak={rightTextWordBreak}
          whiteSpace={rightTextWhiteSpace}
        >
          {handleRightContent()}
        </Txt>
      )}
    </Row>
  );
};
