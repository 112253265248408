import { DateFormats } from "@/common/dates/DateFormats";
import { format, formatDistanceToNow, parseISO } from "date-fns";

interface GQLDate {
  isoString: string;
}

interface GQLDateRange {
  start:
    | {
        isoString: string;
      }
    | null
    | undefined;
  end:
    | {
        isoString: string;
      }
    | null
    | undefined;
}

export const formatGQLDate = (date: GQLDate | null | undefined) =>
  date ? formatDate(date.isoString) : "-";

export const formatGQLDateTime = (date: GQLDate | null | undefined) =>
  date ? formatDateTime(date.isoString) : "-";

export const formatGQLDateTimeRelative = (date: GQLDate | null | undefined) =>
  date ? formatDateTimeRelative(date.isoString) : "-";

export const formatDate = (isoString: string) =>
  format(parseISO(isoString), DateFormats.fullDate);

export const formatDateRelative = (isoString: string) =>
  formatDistanceToNow(parseISO(isoString), { addSuffix: true });

export const formatDateTime = (isoString: string) =>
  format(parseISO(isoString), DateFormats.fullDateAndTime);

export const formatDateTimeRelative = (isoString: string) =>
  formatDistanceToNow(parseISO(isoString), { addSuffix: true });

export const formatGQLDateRange = (dateRange: GQLDateRange): string =>
  formatDateRange(dateRange.start?.isoString, dateRange.end?.isoString);

// 1200 -> 12:00 AND 9:00 -> 09:00
export const formatHoursMinutesWithColon = (time: string) => {
  if (!time) {
    return "";
  }

  let output = time.length === 3 ? "0" + time : time;
  return output.slice(0, 2) + ":" + output.slice(2);
};

export const formatDateRange = (
  start: string | null | undefined,
  end: string | null | undefined
): string => {
  try {
    return `${start ? formatDate(start) : "-"} - ${
      end ? formatDate(end) : "-"
    }`;
  } catch (e) {
    throw new Error(`Unable to format DateRange. start: ${start} end: ${end}`);
  }
};
